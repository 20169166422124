<template>
  <div class="teacher">
    <list-template
        :loading="loading"
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        :search-config="searchConfig"
        :current-page="page"
        @onSearch="onSearch"
        @onReset="search = null"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        hasAdd
        @onAdd="onAdd"
        @onBatchImport="onBatchImport"
    >
    </list-template>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <span>是否删除此宿舍?</span>
      <span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">点错了</el-button>
				<el-button type="primary" @click="confirm">删除</el-button>
			</span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible_" title="批量导入">
      <div style="color: #000;font-weight: 600;margin-bottom: 24px">
        注意：
        <div style="margin-top: 12px">1.导入时校区名称，宿舍楼名称应与系统内完全一致</div>
        2.仅可批量创建房间，居住人在新生分班模块维护
      </div>
      <div>
        <span style="display: inline-block;width: 180rem">下载模板</span>
        <el-button size="small" @click="onDownload">点此下载模板</el-button>
      </div>
      <div style="margin: 24rem 0;display: flex;align-items: center">
        <span style="display: inline-block;width: 180rem">上传文件</span>
        <el-upload :action="$_axios2.defaults.baseURL + '/api/base/dormitory-import'" :before-upload="beforeUpload" :headers="{ authorization: 'Bearer ' + $store.getters.token }"
                   :on-success="onSuccess" :show-file-list="false" accept=".xlsx,.xls" class="upload-demo" name="file" style="width: 100rem">
          <el-button size="small" type="primary">点此上传文件</el-button>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {delDormitory} from './api'
import {mapState} from "vuex";
import tools from "@/utils/tools";

export default {
  _config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
  data() {
    return {
      loading: true,
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'name',
          placeholder: '搜索宿舍名称'
        },

        {
          prop: 'school_id',
          tag: 'select',
          placeholder: '筛选校区',
          options: [],
          label: 'school_name',
          value: 'id'
        },
        {
          prop: 'buliding_id',
          tag: 'select',
          placeholder: '筛选宿舍楼',
          options: [],
          label: 'dimicile_buliding_name',
          value: 'id'
        },
        {
          prop: 'is_man',
          tag: 'select',
          placeholder: '筛选是否满员',
          options: [
            {label: '满员', value: '1'},
            {label: '未满员', value: '0'}
          ]
        }
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'name',
          label: '宿舍名称'
        },
        {
          prop: 'buliding_name',
          label: '宿舍楼'
        },
        {
          prop: 'floor',
          label: '楼层'
        },
        {
          prop: 'school_name',
          label: '所属校区',
          render(h) {
            return h?.school_info?.school_name || '-'
          }
        },
        {
          prop: 'is_man',
          label: '是否满员',
          render(h) {
            return Number(h.is_man) === 0 ? '未满员' : '满员'
          }
        },
        {
          prop: 'livecount',
          label: '当前居住人数'
        },
        {
          label: '操作',
          handle: true,
          width: 120,
          buttons: [{type: "edit", text: "编辑"}, {type: "delete", text: "删除"}]
        }
      ],
      search: null,
      total: 0,
      dialogVisible: false,
      dialogVisible_: false,
      // 表格中的数据
      tableData: [],
      // 删除宿舍的id
      dormitoryId: ''
    }
  },
  computed: {
    ...mapState(["page"])
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData()
    this.$_axios2.get('/api/common/base-school', {params: {usage: 3}}).then(res => {
      this.searchConfig[1].options = res.data.data.school
      this.searchConfig[2].options = res.data.data.building
    })
  },
  activated() {
    this.getData()
  },
  methods: {
    changeCurrentPage(e) {
      this.$store.commit("setPage", e);
      this.getData()
    },
    getData() {
      let {search, page} = this
      this.loading = true;
      this.$_axios.get('dormitory', {params: {...search, page}}).then(res => {
        let {data} = res.data
        this.tableData = data
        let {total} = this.$tools.getPaginationInfo(res.headers)
        this.total = total
      }).finally(() => this.loading = false);
    },
    onAdd() {
      this.$router.push('./add')
    },
    onSearch(val) {
      this.$store.commit("setPage", 1);
      this.search = val
      this.getData()
    },
    tableHandle(row, handle) {
      const {type} = handle
      if (type === 'edit') {
        this.$router.push({path: './edit', query: {id: row.id}})
      } else if (type === 'delete') {
        this.dialogVisible = true
        this.dormitoryId = row.id
      }
    },
    confirm() {
      delDormitory(this.dormitoryId)
          .then((res) => {
            // this.$message.success('删除成功')

            this.dialogVisible = false
            this.getData()
          })
          .catch(() => {
            this.dialogVisible = false
          })
    },
    onBatchImport() {
      this.dialogVisible_ = true
    },
    onDownload() {
      tools.newFilesName('https://main.pro.honghueducation.com:8003/bf-static/school-system/preview/dormitory.xlsx', '宿舍管理模版.xlsx')
    },
    beforeUpload(file) {
      const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'xls'
      const extension2 = testmsg === 'xlsx'
      if (!extension && !extension2) {
        this.$message({
          message: '上传文件只能是xls、xlsx格式!',
          type: 'warning'
        })
      }
      return extension || extension2
    },

    onSuccess(res) {
      if (res.status === 0) {
        this.$message.success('导入完毕，请在企业微信中查收邮件。')
        this.dialogVisible_ = false
        this.$store.commit("setPage", 1);
        this.getData()
      } else this.$message.error(res.msg)
    },
  }
}
</script>

<style scoped lang="scss">
</style>
